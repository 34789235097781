@import '../abstracts/variables';

.header{
  &-franchisor {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    min-width: $page-width-min;
    width: 100%;
    height: $header-height;
  }
  &__button{
    &_logout{
      height: 100%;
      float: right;
      min-width: unset !important;
      border: none !important;
      color: inherit !important;
    }
  }

  &-franchisee {
    background: $color-brown;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: $header-height-franchisee;
    box-shadow: none;
    padding: 0 !important;

    &__logo {
      height: $header-logo-height;
    }

    &__title {
      font-size: $fontSize-sm;
      font-weight: bold;
      color: $color-white;
      &_role{
        font-size: $fontSize-sm;
        color: $color-white;
      }

      &_with-p {
        padding-left: $spacer-4;
        padding-right: $spacer-4;
      }
    }

    &__desktop-logout-btn, &__desktop-logout-btn:hover {
      color: $color-white !important;
      font-family: $fontFamily-default;
      font-size: $fontSize-sm;
      background: transparent !important;
      border: none;
      box-shadow: none;
    }
  }
}