@import '../abstracts/variables';

.flex {
  &-noWrap {
    flex-wrap: nowrap;
  }
  &__item {
    &_1 {
      flex: 1;
    }
  }
}