@import '../abstracts/variables';

.header-franchisee-menu {
  font-family: $fontFamily-default;
  .ant-menu-item {
    min-width: unset;
    margin: 0 $spacer-9 !important;
  }
  &_mobile {
    &.ant-menu-vertical {
      margin-top: $spacer-6;

      .ant-menu-item,
      .ant-menu-item-selected,
      .ant-menu-item-active,
      .ant-menu-submenu,
      .ant-menu-submenu-title {
        padding: 0 $spacer-4 !important;
        font-weight: bold !important;
      }
    }
  }
  &__link-item {
    height: auto !important;
    line-height: 22px;
    &.ant-menu-item {
      font-size: $fontSize-xxxs;
      text-decoration: underline;
    }

    &.ant-menu-item-selected {
      color: $color-white !important;
      font-weight: bold;
    }
  }

  &.ant-menu {
    background: transparent;
    border: none !important;
    padding: unset !important;
  }

  .ant-menu-item,
  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-submenu,
  .ant-menu-submenu-title {
    color: $color-white !important;
    background: transparent !important;
    border: none !important;
    font-size: $fontSize-sm;
  }

  &__activilable-item {
    &.ant-menu-item-selected {
      color: $color-white !important;
      font-family: $fontFamily-default;
      font-weight: bold !important;
    }
  }

  &__burger {
    float: left;
  }

  &__drawer {
    .ant-drawer-body {
      background: $color-brown;
      padding-bottom: $spacer-5;
    }
  }
}
